import React from "react";
import styled from "styled-components";

type Props = {
  className?: any
  message?: any
}

const Message = ({ className, message }: Props) => {
  return <div className={className}>{message}</div>;
};

export default styled(Message)`
  padding: 10px;
  max-width: 200px;
  font-size: 12px;
  word-wrap: break-word;
`;
