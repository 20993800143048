import React from "react";
import styled from "styled-components";
import Skeleton from "./Skeleton";

type Props = {
  className?: string;
};

const MessageSkeleton = ({ className }: Props) => {
  return (
    <section className={className}>
      <Skeleton height={"50px"} width={"150px"} />
      <Skeleton height={"50px"} width={"150px"} />
      <Skeleton height={"50px"} width={"150px"} />
      <Skeleton height={"50px"} width={"150px"} />
      <Skeleton height={"50px"} width={"150px"} />
      <Skeleton height={"50px"} width={"150px"} />
    </section>
  );
};

export default styled(MessageSkeleton)`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  section:nth-child(2n + 1) {
    margin-left: 160px;
  }
`;
