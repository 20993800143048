import React, { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  children?: ReactNode
}

const Body = ({ className, children }: Props) => {
  return <section className={className}>{children}</section>
}

export default styled(Body)`
  background: #faf9f8;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.secondaryColor};
    border-radius: 4px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0 8px 8px 0;
  }

  .wrapper-other {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 18px;
  }
`
