import React from 'react'
import styled from 'styled-components'
import Avatar from './Avatar'

type Props = {
  className?: string
  contact: any
  onClick: (contact: any) => void
  notification: any
}

const CardUser = ({ className, contact, onClick, notification }: Props) => {

  const handleClick = () => {
    onClick(contact)
  }

  return (
    <div className={className} onClick={handleClick}>
      <Avatar>
        {notification?.content?.activeNotification &&  <span className="inbox__notification">{notification?.content?.counter > 0 && notification?.content?.counter}</span>}
      </Avatar>
      <div className="wrapper__text">
        <div className="__username">{contact.name}</div>
        <div className="__last-message">{notification?.content?.lastMessage}</div>
      </div>
    </div>
  )
}

export default styled(CardUser)`
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.08);
  min-height: 65px;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #768190;
  width: 100%;
  cursor: pointer;
  transition: filter 500ms;

  &:hover {
    filter: brightness(95%);
  }

  .inbox__notification {
    height: 14px;
    width: 14px;
    background: #95190c;
    border-radius: 50%;
    position: absolute;
    top: -3px;
    right: -3px;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 8px;

  }

  .wrapper__text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 12px;
    height: 35px;

    .__username {
      font-weight: bold;
      font-size: 12px;
      max-width: 200px;
    }

    .__last-message {
      white-space: nowrap; 
      overflow: hidden;
      font-size: 9px;
      max-width: 200px;
      text-overflow: ellipsis;
    }

  }

`
