import React from "react";
import styled from "styled-components";
import Time from "./Time";
import MessageImage from "./MessageImage";
import Message from "./Message";

type Props = {
  className?: string;
  message?: any;
  source?: any;
  ref?: any;
  isLoading?: any
};

const CardMessage = ({ className, message, source }: Props) => {
  const verifyHostName = (url: any) => {
    return url.indexOf(`https://firebasestorage`) !== -1;
  };

  return (
    <div className={className}>
      <div className="container__message">
        <div className="__message">
          {verifyHostName(message?.content) ? (
            <MessageImage image={message?.content} />
          ) : (
            <Message message={message.content} />
          )}
        </div>
        {message.timestamp && (
          <Time time={message.timestamp.seconds} source={source}/>
        )}
      </div>
    </div>
  );
};

export default styled(CardMessage)`
  display: flex;
  text-align: ${(props) =>
    props.source === "me" ? "end" : "start"};
  justify-content: ${(props) =>
    props.source === "me" ? "flex-end" : "flex-start"};
  margin-bottom: 18px;

  .container__message {
    display: flex;
    flex-direction: column;
    position: relative;

    .__message {
      color: ${(props) => (props.source === "me" ? props.theme.messageMeColor : props.theme.messageOtherColor)};
      background: ${(props) => (props.source === "me" ? props.theme.messageMeBackground : props.theme.messageOtherBackground)};
      border-radius: ${(props) =>
        props.source === "me" ? "12px 0 12px 12px" : "12px 12px 12px 0"};
      box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.08);
    }
  }
`;
