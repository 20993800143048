import React, { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  className?: string;
  children?: ReactNode;
};

const Footer = ({ className, children }: Props) => {
  return <footer className={className}>{children}</footer>;
};

export default styled(Footer)`
  margin-top: 0 !important;
  background: ${(props) => props.theme.primaryColor};
  padding: 8px;
  border-radius: 0 0 8px 8px;
  display: block;
`;
