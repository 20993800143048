import React, {
  useState,
  useEffect,
  createContext,
  useCallback,
  ReactNode,
} from "react";
import { IChatConfig, IUser } from "../types";

export const initialState: any = {
  sendMessage: () => undefined,
  contacts: undefined,
  onMessage: () => undefined,
  getChannel: () => undefined,
  notifyTarget: () => undefined,
  notifications: undefined,
  hasAlert: () => undefined,
  removeTargetNotification: () => undefined,
  user: undefined,
  conversations: undefined,
  counterAlert: () => undefined,
  connection: () => undefined,
  oneContact: false,
  provider: undefined
}


export const firebaseChatContext = createContext<any>(initialState);

type Props = {
  children?: ReactNode
  users?: IUser[]
  user: IUser
  connection: any
  config?: IChatConfig
}

const FirebaseChatContextProvider = ({ children, users, user, connection, config }: Props) => {
  const [contacts, setContacts] = useState<any>([])
  const [conversations, setConversations] = useState<any>([])
  const [notifications, setNotifications] = useState<any>([])
  const [firebaseUsers, setFirebaseUsers] = useState<any>([])
  const [errors, setErrors] = useState<any>([])
  const [oneContact, setOneContact] = useState(false)
  const [provider, setProvider] = useState<string>('')

  const getContactType = () => {
    if(config?.oneContact && users?.length === 1) {
      setOneContact(true)
    }
  }

  useEffect(() => {
    if (config) {
      setProvider(config?.provider!)
    }
  }, [config])

  useEffect(() => {
    getContactType()
  }, [config, users])

  const db = connection?.firestore()

  const hasAlert = useCallback(() => {
    return Object.values(notifications).some((n: any) => n?.content?.activeNotification)
  }, [notifications])

  const counterAlert = useCallback(() => {
    return notifications?.reduce((accumulator: number, data: any) => {
      if (data?.content?.activeNotification) {
        return accumulator + 1
      }
      return accumulator
    }, 0)
  },[notifications])

  const getContacts = () => {
    setContacts(users);
  };

  const sortContactsByNotifications = () => {
    let sort: any = []
    contacts.map((r: any) => {
      const results = notifications.find((x: any) => x.id === r.id.toString())
      if (results?.id && results?.content?.lastMessage) {
        sort.push({ id: results?.id, name: r.name, timestamp: results?.content?.timestamp, lastMessage: results?.content?.lastMessage })
      }
    })
    sort.sort((a: any, b: any) => b.timestamp?.seconds - a.timestamp?.seconds)
    setConversations(sort)
  }


  const onMessage = (callback: any, channel: any) => 
    db?.collection(`chat/exams/${config?.provider}/conversations/${channel}`)
      .orderBy("timestamp")
      .onSnapshot((querySnapshot: any) => {
        const messages: any = []
        querySnapshot.forEach((d: any) => messages.push(d.data()))
        callback(messages);
      })

  const createNotificationListener = useCallback(() => {
    if (user) {
      db?.collection(`chat/exams/${config?.provider}/notifications/${user?.id}`).onSnapshot((docSnapshot: any) => {
        const notifiers: any = []
        const listContacts: any = []
        docSnapshot.docs.map((r: any) => {
          notifiers.push({id:r.id, content: r.data()})
          listContacts.push({id:r.id, timestamp: r.data()?.content?.timestamp})
        })
        setFirebaseUsers(listContacts)
        setNotifications(notifiers)
      })
    }
  }, [user])

  useEffect(() => {
    sortContactsByNotifications()
  },[firebaseUsers, notifications])

  const getChannel = useCallback(
    (target) => {
      const ids = [user?.id, target?.id].sort();
      return `${ids[0]}-${ids[1]}`
    },
    [user]
  )

  const notifyTarget = (target: any, content: any) => {
    db?.collection('chat')
      .doc('exams')
      .collection(`${config?.provider}`)
      .doc(`notifications`)
      .collection(`${target?.id}`)
      .doc(`${user?.id}`)
      .set(
        {
          counter: connection.firestore.FieldValue.increment(1),
          activeNotification: true,
          lastMessage: content,
          timestamp: connection.firestore.FieldValue.serverTimestamp()
        },
        { merge: true }
      )
  }

  const notifySource = (target: any, content: any) => {
    db?.collection('chat')
      .doc('exams')
      .collection(`${config?.provider}`)
      .doc(`notifications`)
      .collection(`${user?.id}`)
      .doc(`${target?.id}`)
      .set(
        {
          lastMessage: content,
          timestamp: connection.firestore.FieldValue.serverTimestamp()
        },
        { merge: true }
      )
  }

  const removeTargetNotification = (target: any) => {
    db?.collection('chat')
      .doc('exams')
      .collection(`${config?.provider}`)
      .doc(`notifications`)
      .collection(`${user?.id}`)
      .doc(`${target?.id}`)
      .set(
        {
          counter: 0,
          activeNotification: false,
        },
        { merge: true }
      )
  }

  const sendMessage = async (content: any, target: any) => {
    const channel = getChannel(target)
    try {
      await db?.collection(`chat/exams/${config?.provider}/conversations/${channel}`).add({
        content: content,
        timestamp: connection.firestore.FieldValue.serverTimestamp(),
        source: user?.id,
        target: target?.id,
      })
     notifyTarget(target, content)
     notifySource(target, content)
    } catch (error) {
      setErrors([...errors, { writeError: error }])
    }
  }

  useEffect(() => {
    createNotificationListener()
  }, [createNotificationListener])

  useEffect(() => {
    getContacts()
  }, [])

  return (
    <firebaseChatContext.Provider
      value={{
        sendMessage,
        contacts,
        onMessage,
        getChannel,
        notifyTarget,
        notifications,
        hasAlert,
        counterAlert,
        removeTargetNotification,
        user,
        conversations,
        connection,
        oneContact,
        provider
      }}
    >
      {children}
    </firebaseChatContext.Provider>
  );
};

export default FirebaseChatContextProvider;
