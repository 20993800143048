import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots, faTimes } from "@fortawesome/pro-regular-svg-icons";

type Props = {
    className?: string
    handleClick?: () => void
    open?: boolean
    popupVisible?: boolean
}

const topButton =({ className, handleClick, popupVisible }: Props) =>{

    return ( 
        <button 
            className={className} 
            onClick={handleClick}
            style={{width: popupVisible ? '60px' : '40px' }}
        >
            {popupVisible ? (
                <FontAwesomeIcon icon={faTimes}/>
            ) : (     
                <FontAwesomeIcon icon={faCommentDots}/>
            )}
        </button>
    );
};

export default styled(topButton)`
    position: fixed;
    right: 0px;
    top: 33vh;
    z-index: 9999999999;
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width 400ms;

    height: 50px;

    background: ${(props) => props.theme.primaryColor};
    border-radius: 4px 0 0 4px;
    box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.08);

    svg {
    color: #fff;
    height: 25px !important;
    width: 25px !important;
    }
`