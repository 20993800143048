import React, {
  useState,
  useContext,
} from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faAngleRight,
  faSpinner,
} from "@fortawesome/pro-regular-svg-icons";
import ImagePreview from "./ImagePreview";
import { generateRandomNumber } from "../utils/numbers";
import { firebaseChatContext } from "../ChatState";
import Error from "./Error";

type Props = {
  className?: string;
  handleClose: (e: any) => void;
  contact: any;
};

const FileImport = ({ className, handleClose, contact }: Props) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [selectedUrl, setSelectedUrl] = useState("");
  const { user, sendMessage, connection, provider } = useContext(firebaseChatContext);
  const allowedMimeTypes = ["image/jpeg", "image/png"];
  const fileSizeLimit = 1024 * 1024 * 10;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ error: false, motive: "" });
  const storage = connection.storage()

  const onFileChange = (event: any) => {
    const file = event.target.files[0];
    const url = window.URL.createObjectURL(file);

    if (file.size < fileSizeLimit) {
      if (allowedMimeTypes.includes(file.type)) {
        setSelectedUrl(url);
        setSelectedFile(file);
      } else {
        return setError({
          error: true,
          motive: "Este formato não é suportado.",
        });
      }
    } else {
      return setError({
        error: true,
        motive: "O tamanho do arquivo deverá ser menor que 10Mb",
      });
    }
  };

  const getImageUrl = async () => {
    const imageRef = storage
      .ref()
      .child(
        `${provider}/${user.id}/${generateRandomNumber(99999999999999, 1)}${
          selectedFile.name
        }`
      );
    return await (await imageRef.put(selectedFile)).ref.getDownloadURL();
  };

  const handleClick = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    sendMessage(await getImageUrl(), contact);
    handleClose(e);
  };

  return (
    <div>
      {error.error && <Error title={error.motive} />}
      <form className={className} onSubmit={handleClick}>
        <header>
          <FontAwesomeIcon icon={faTimes} size="2x" onClick={handleClose} />
        </header>
        <section>
          {isLoading ? (
            <div>
              <FontAwesomeIcon icon={faSpinner} spin size="2x" />
            </div>
          ) : (
            <div>
              {selectedFile ? (
                <div>
                  <ImagePreview url={selectedUrl} />
                  <button type="submit">
                    <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                </div>
              ) : (
                <main>
                  <div>
                      <p className='__p'>Arraste</p>
                      <p className='__p'>ou</p>
                    <label htmlFor="select-file">Clique aqui</label>
                    <input
                      type="file"
                      id="select-file"
                      onChange={onFileChange}
                    />
                  </div>
                </main>
              )}
            </div>
          )}
        </section>
      </form>
    </div>
  );
};

export default styled(FileImport)`
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  z-index: 99999999999;

  header {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 55px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    background: transparent;

    svg {
      margin-right: 20px;
      cursor: pointer;
      transition: filter 500ms;

      &:hover {
        filter: brightness(70%);
      }
    }
  }

  section {
    color: #fff;
    position: absolute;
    height: 100%;
    right: 0;
    bottom: 0;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;

    button {
      z-index: 1;
      background: #faf9f8;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit !important;
      bottom: 20px;
      right: 20px;
      position: absolute;

      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: #3b5a86;
      transition: filter 500ms;

      &:hover {
        filter: brightness(80%);
      }
    }

    main {
      height: 220px;
      width: 220px;
      border-radius: 16px;
      border: dashed 10px white;

      display: flex;
      justify-content: center;
      align-items: center;

      div {
        font-size: 21px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 60%;
        align-items: center;

        p {
            margin-top: 0;
          }

        input[type="file"] {
          display: none;
        }

        label {
          font-size: 21px;
          color: #fff;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
`;
