import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  title: string
}

const Error = ({ className, title }: Props) => {
  return (
    <div className={className}>
      <div className="error__container">
        <div className="error__border">
          <p>{title}</p>
        </div>
      </div>
    </div>
  )
}

export default styled(Error)`
  top: 50px;
  height: 100px;
  width: 100%;
  position: absolute;
  z-index: 9999999999999999;

  display: flex;
  justify-content: center;
  align-items: center;

  .error__container {
    width: 80%;
    height: 80%;
    background: #95190c;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    .error__border {
      width: 90%;
      height: 80%;
      border-radius: 8px;
      border: 1px dashed #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        color: #fff;
        margin-top: 20px;
        font-size: 12px;
      }
    }
  }
`
