import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import Header from './components/Header'
import Input from './components/Input'
import Footer from './components/Footer'
import ListContact from './ListContact'
import Messages from './components/Messages'
import FileImport from './components/FileImport'
import { firebaseChatContext } from './ChatState'

type Props = {
  className?: string
}

const Inbox = ({ className }: Props) => {
  const [showFileImport, setShowFileImport] = useState(false)
  const [showMessages, setShowMessages] = useState(false)
  const [contact, setContact] = useState<any>(undefined)
  const [listChoice, setListChoice] = useState<string>('CONVERSATION')
  const { contacts, conversations, oneContact } = useContext(firebaseChatContext)

  const handleOpenMessages = (currentContact: any) => {
    setShowMessages(true)
    setContact(currentContact)
  }

  const handleBackToListContact = () => {
    setShowMessages(false)
  }

  const handleOpenFileImport = (e: any) => {
    e.preventDefault()
    setShowFileImport(!showFileImport)
  }

  const handleChoiceList = (value: string) => {
    setListChoice(value)
  }

  return (
    <div className={className}>
      {showFileImport && (
        <FileImport handleClose={handleOpenFileImport} contact={oneContact ? contacts[0] : contact} />
      )}
      {!showMessages && !oneContact ? (
        <div className="wrapper__body">
          <Header hasMenu={true} handleClick={handleChoiceList} buttonFocus={listChoice}/>
          {listChoice === 'CONTACTS' ? (
            <ListContact handleClick={handleOpenMessages} contacts={contacts} type="CONTACTS"/>
          ) : (
            <ListContact handleClick={handleOpenMessages} contacts={conversations} type="CONVERSATIONS"/>
          )}
          <div className="__space">
          <Footer />
          </div>
        </div>
      ) : (
        <div className="wrapper__body">
          <Header
            title={oneContact ? contacts[0].name : contact.name}
            back={oneContact ? false : true}
            handleClick={handleBackToListContact}
          />
          <Messages contact={oneContact ? contacts[0] : contact} />
          <div className="__space">
            <Footer>
              <Input contact={oneContact ? contacts[0] : contact} fileImport={handleOpenFileImport} />
            </Footer>
          </div>
        </div>
      )}
    </div>
  )
}

export default styled(Inbox)`
  
  position: fixed;
  bottom: 100px;
  right: 20px;
  z-index: 9999999999 !important;


  .wrapper__body {
    font-family: ${(props) => props.theme.fontFamily};
    box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    width: 346px;
    background: #faf9f8;
    height: 550px;
    display: grid;
    grid-template-rows: 55px auto auto;
  }


  .__space {
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

`
