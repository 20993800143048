import React, { ReactNode } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons";

type Props = {
  className?: string;
  group?: boolean;
  children?: ReactNode;
};

const Avatar = ({ className, children }: Props) => {
  return (
    <div className={className}>
      <FontAwesomeIcon icon={faUser} />
      {children}
    </div>
  );
};

export default styled(Avatar)`
  position: relative;
  flex-shrink: 0;
  margin-left: 12px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: #faf9f8;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 18px !important;
    width: 18px !important;
    color: #768190;
  }
`;
