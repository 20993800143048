import React, {  useEffect, useState } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";

interface Props {
    className?: string
    filter: (inputValue: string) => any
}

const SearchCard = ({ className, filter }: Props) => {

    const [ inputValue, setInputValue ] = useState('')

    useEffect(() => {
        filter(inputValue)
    }, [inputValue])

    return (
        
        <div className={className}>
            <div className="search__wrapper">
                <input 
                    placeholder="Pesquisar..."
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                />
                <FontAwesomeIcon icon={faSearch} />
            </div>
        </div>
    )
}

export default styled(SearchCard)`
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.08);
  height: 45px;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #768190;
  width: 100%;
  
  .search__wrapper {
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
        height: 15px;
        width: 15px;
    }

    input {
        color: #768190;
        font-weight: 500;
        font-size: 12px;
        width: 200px;
        height: 28px;
        border-radius: 4px;
        background: #faf9f8;
        border: none;
        padding: 0 20px;

        &:focus {
        outline: none;
        }
        &::placeholder {
            color: #c4c4c4;
        }
    }
  }


`