import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { firebaseChatContext } from "../ChatState";
import Body from "./Body";
import CardMessage from "./CardMessage";
import MessageSkeleton from "./MessageSkeleton";

type Props = {
  className?: string;
  contact?: any;
};

const Messages = ({ className, contact }: Props) => {
  const { user, getChannel, onMessage, removeTargetNotification } = useContext(
    firebaseChatContext
  );
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState<any>([]);

  useEffect(() => {
    {messages?.length === 0 && setIsLoading(true)}
    const unsub = onMessage((mes: any) => {
      setMessages(mes);
      removeTargetNotification(contact);
      setIsLoading(false);
    }, getChannel(contact));

    return () => unsub();
  }, [contact, getChannel, onMessage, removeTargetNotification]);

  return (
    <Body className={className}>
      {isLoading && <MessageSkeleton />}
      {messages.map((message: any, i: any) => {
        const source = message.source === user.id ? "me" : "other";
        return (
          <CardMessage
            isLoading={isLoading}
            source={source}
            key={i}
            message={message}
          />
        );
      })}
    </Body>
  );
};

export default styled(Messages)`
  padding: 20px;
`;
