import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faPaperclip } from '@fortawesome/pro-regular-svg-icons'
import { firebaseChatContext } from '../ChatState'

type Props = {
  className?: any
  contact?: any
  fileImport?: any
}

const Input = ({ className, contact, fileImport }: Props) => {
  const [newMessage, setNewMessage] = useState('')
  const { sendMessage } = useContext(firebaseChatContext)
  const [rows, setRows] = useState<number>(1)

  const handleMessage = (e: any) => {
    e.preventDefault()
    if (newMessage.length) {
      setNewMessage('')
      setRows(1)
      sendMessage(newMessage, contact)
    }
  }

  const onKeyPress = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      handleMessage(e)
    }
  }

  const handleChange = (e: any) => {
    const minRows = 1
    const maxRows = 5

    const textareaLineHeight = 20
    const previousRows = e.target.rows
    e.target.rows = minRows
    const currentRows = ~~(e.target.scrollHeight / textareaLineHeight)

    if (currentRows === previousRows) {
      e.target.rows = currentRows
    }

    if (currentRows >= maxRows) {
      e.target.rows = maxRows
      e.target.scrollTop = e.target.scrollHeight
    }
    setNewMessage(e.target.value)
    setRows(currentRows < maxRows ? currentRows : maxRows)
  }

  return (
    <form className={className}>
      <textarea
        rows={rows}
        value={newMessage}
        placeholder='Escreva algo...'
        onChange={handleChange}
        onKeyPress={onKeyPress}
      />
      {newMessage.length !== 0 ? (
        <button onClick={handleMessage}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      ) : (
        <button onClick={fileImport}>
          <FontAwesomeIcon icon={faPaperclip} />
        </button>
      )}
    </form>
  )
}

export default styled(Input)`
  padding: 0;
  margin: 0;
  textarea {
    box-sizing: border-box;
    display: block;
    min-height: 35px;
    line-height: 20px;
    width: 326px;
    border-radius: 4px;
    border: none;
    font-size: 14px;
    color: #768190;
    resize: none;
    padding: 8px 38px 0 15px;

    &:focus {
      outline: none;
      padding: 8px 38px 0 15px;
    }

    ::-webkit-scrollbar {
      display: unset;
      width: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: lightgray;
      border-radius: 4px;
      cursor: pointer;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  button {
    position: absolute;
    right: 22px;
    bottom: 14px;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit !important;


    svg {
      height: 17px !important;
      width: 17px !important;
      color: #c3c3c3;
      transition: filter 500ms;

      &:hover {
        filter: brightness(90%);
      }
    }
  }
`
