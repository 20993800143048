import React from "react";
import styled from "styled-components";

type Props = {
  className?: string;
  image?: any;
};

const MessageImage = ({ className, image }: Props) => {
  const showImage = (documentUrl: any) => {
    const link = document.createElement("a");
    link.href = documentUrl;
    link.target = "_blank";
    link.click();
  };

  return (
    <img
      className={className}
      src={image}
      alt="message-image"
      onClick={() => showImage(image)}
    />
  );
};

export default styled(MessageImage)`
  flex-shrink: 0;
  width: 180px;
  margin: 10px;
  object-fit: contain;
  cursor: pointer;
`;
