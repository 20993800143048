import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import CardUser from './components/CardUser'
import { firebaseChatContext } from './ChatState'
import Skeleton from './components/Skeleton'
import Body from './components/Body'
import SearchCard from './components/SearchCard'
import { lowerCaseStringAndRemoveAccent } from './utils/string'

type Props = {
  className?: string
  handleClick: (currentContact: any) => void
  contacts?: any
  type: string
}

const ListUsers = ({ className, handleClick, contacts, type }: Props) => {
  const { notifications } = useContext(firebaseChatContext)
  const [currentContact, setCurrentContact] = useState<any>(undefined)
  const [ contactsFiltered, setContactsFiltered] = useState<any>([])

  useEffect(() => {
    if (currentContact) {
      handleClick(currentContact)
    }
  }, [currentContact])

  const filterContacts = ( searchInput: string) => {
    lowerCaseStringAndRemoveAccent(searchInput)
    setContactsFiltered(contacts?.filter(
      (contact: any) => lowerCaseStringAndRemoveAccent(contact?.name)?.includes(lowerCaseStringAndRemoveAccent(searchInput)))
    )
  }

  return (
    <Body className={className}>
      {contacts === undefined ? (
        <div>
          <Skeleton height='65px' width='95%' />
          <Skeleton height='65px' width='95%' />
          <Skeleton height='65px' width='95%' />
        </div>
      ) : (
        <div>
          {contacts?.length === 0 ? (
            <div className="empty__conversation">
              Você não tem nenhuma conversa ainda, que tal iniciar uma?
            </div>
          ) : (
            <div>
              {contacts?.length > 1 && type === 'CONTACTS' ? (
                <div>
                  <SearchCard filter={filterContacts}/>
                   {contactsFiltered?.map((contact: any) => {
                      const notifier =  notifications.find((x: any) => x.id === contact?.id?.toString())
                      return (
                        <CardUser
                          key={contact.id}
                          notification={notifier}
                          contact={contact}
                          onClick={setCurrentContact}
                        />
                      )
                    })}
                </div>
              ) : (
                <div>
                  {contacts.map((contact: any) => {
                    const notifier =  notifications.find((x: any) => x.id === contact?.id?.toString())
                    return (
                      <CardUser
                        key={contact.id}
                        notification={notifier}
                        contact={contact}
                        onClick={setCurrentContact}
                      />
                    )
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Body>
  )
}

export default styled(ListUsers)`
  padding: 10px;

  .empty__conversation {
    margin: 20px 5px 5px 5px;
    color: #c4c4c4;
  }

`
