import React from 'react'
import styled from 'styled-components'
import Avatar from './Avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'

type Props = {
  className?: any
  title?: any
  back?: any
  handleClick?: any
  hasMenu?: boolean
  buttonFocus?: string
}

const Header = ({ className, title, back, handleClick, hasMenu = false }: Props) => {
  return (
    <header className={className}>
      {hasMenu ? (
        <div className="menu__button">
          <button className="button__conversation" onClick={() => handleClick('CONVERSATION')}>Conversas</button>
          <button className="button__contacts" onClick={() => handleClick('CONTACTS')}>Contatos</button>
        </div>
      ) : (
        <div className="menu__normal">
          {back && (
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="__back"
              onClick={handleClick}
            />
          )}
          <Avatar />
          <div className="wrapper__title">
            <div className="__title">{title}</div>
          </div>
        </div>
      )}
    </header>
  )
}

export default styled(Header)`
  color: #fff;
  border-radius: 8px 8px 0 0;
  background: ${(props) => props.theme.primaryColor};
  display: flex;
  align-items: center;

  .menu__normal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 8px 8px 0 0;
  }

  .__back {
    margin-left: 12px;
    cursor: pointer;
    transition: filter 500ms;
    height: 15px !important;
    width: 15px !important;

    &:hover {
      filter: brightness(70%);
    }
  }

  .wrapper__title {
    margin-left: 12px;

    .__title {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .menu__button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;

    button {
      border: none;
      font: inherit;
      cursor: pointer;
      outline: inherit !important;
      height: 100%;
      width: 50%;
      font-weight: 600;
      font-size: 14px;
      color: #fff;
      transition: filter 500ms;

      &:hover {
        filter: brightness(95%);
      }
      
      &:nth-child(odd) {
        border-radius: 8px 0 0 0 ;
      }

      &:nth-child(even) {
        border-radius: 0 8px 0 0 ;
      }
    }

    .button__conversation {
      background: ${(props) => {
        switch (props.buttonFocus) {
          case 'CONVERSATION':
            return props.theme.primaryColor
          case 'CONTACTS':
            return props.theme.secondaryColor
        }
      }};
    }

    .button__contacts {
      background: ${(props) => {
        switch (props.buttonFocus) {
          case 'CONTACTS':
            return props.theme.primaryColor
          case 'CONVERSATION':
            return props.theme.secondaryColor
        }
      }};
    }


  }
`
