import React, { useRef } from "react";
import styled from "styled-components";
import { formatTimeStamp } from "../utils/time";

type Props = {
  className?: any
  time?: any
  source?: any
}


const Time = ({ className, time }: Props) => {
  const inboxRef = useRef<any>(null);
  if (inboxRef.current) {
    inboxRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className={className} ref={inboxRef}>
      {formatTimeStamp(time)}
    </div>
  );
};

export default styled(Time)`
  display: flex;
  justify-content: ${(props) =>
    props.source === "me" ? "flex-end" : "flex-start"};
  font-size: 10px;
  margin-top: 4px;
  color: #768190;
`;
