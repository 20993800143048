import React from 'react'
import styled from 'styled-components'

type Props = {
    className?: any
    url?: any
}

const ImagePreview = ({ className, url}: Props) => {
    return (
        <img src={url} alt="preview image" className={className}/>
    )
}

export default styled(ImagePreview)`
    width: 250px;
    max-height: 350px;
`
