import React, { useState } from "react";
import Button from "./components/Button";
import FirebaseChatContextProvider from "./ChatState";
import Inbox from "./Inbox";
import { IChatConfig, IUser } from "../types";
import TopButton from './components/TopButton';

interface Props {
  contacts?: IUser[] 
  user: IUser
  connection: any
  config?: IChatConfig
}

const Chat = ({contacts, user, connection, config}: Props) => {

  const [showInbox, setShowInbox] = useState(false);

  const handleOpenPopup = () => {
    setShowInbox(!showInbox)
  }
 
  return (
    <FirebaseChatContextProvider 
      users={contacts} 
      user={user} 
      connection={connection} 
      config={config}
    >
    <div>
     {config?.button === 'BALLOON' || !config?.button ? (
        <Button handleClick={handleOpenPopup} open={showInbox} />
     ): config?.button === 'TOPBUTTON' && (
        <TopButton 
          handleClick={handleOpenPopup}
          popupVisible={showInbox}
        />
     )}
        {showInbox && <Inbox />}
      </div>
    </FirebaseChatContextProvider>
  );
};

export default Chat
