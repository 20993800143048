import React, { ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'
import { IThemeProvider } from './types'

type Props = {
  children: ReactNode
  themeProvider?: IThemeProvider
}

const Theme = ({ children, themeProvider }: Props) => {
  return <ThemeProvider theme={themeProvider}>{children}</ThemeProvider>
}

export default Theme
