import React, { useContext } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { firebaseChatContext } from "../ChatState";

type Props = {
  className?: string
  handleClick: () => void
  open: boolean
}

const Button = ({ className, handleClick, open }: Props) => {
  const { hasAlert, counterAlert } = useContext(firebaseChatContext);

  return (
      <button className={className} onClick={handleClick}>
        {open ? (
          <FontAwesomeIcon icon={faTimes} />
        ) : (
          <FontAwesomeIcon icon={faCommentDots} />
        )}
        {hasAlert() && <span className="chat-alert">{counterAlert()}</span>} 
      </button>
  );
};

export default styled(Button)`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999999999;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit !important;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 60px;
  width: 60px;
  background: ${(props) => props.theme.primaryColor};
  border-radius: 50%;
  box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.08);

  svg {
    color: #fff;
    height: 28px !important;
    width: 28px !important;
  }

  .chat-alert {
    position: fixed;
    bottom: 60px;
    right: 19px;
    z-index: 99999999999;

    height: 15px !important;
    width: 15px !important;
    border-radius: 50% !important;

    background: #95190c;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;

    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 8px;

    @keyframes pulse {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }
  }
`;
