import React from 'react'
import Chat from './chat/Chat'
import Theme from './Theme'
import { IUser, IThemeProvider, IConnection, IChatConfig } from './types'
import firebase from 'firebase'

let themes: IThemeProvider
let users: IUser[] = []
let user: IUser
let config: IConnection
let params: IChatConfig

const listUsers = (value: IUser[]) => users = value

const currentUser = ( value: IUser ) => user = value

const chatTheme = (value: IThemeProvider) => themes = value

const connectionConfig = (value: IConnection) => config = value

const chatConfig = (value: IChatConfig) => params = value

const Educhat = () => {
  
  if (config) {
    if (!firebase.apps.length) {
      firebase.initializeApp(config)
    }else {
      firebase.app()
    }
  }

  return (
    <Theme themeProvider={themes}>
      <Chat 
        contacts={users} 
        user={user} 
        connection={config && firebase} 
        config={params}
      />
    </Theme>
  )
}

export default Educhat
export { listUsers, currentUser, chatTheme, connectionConfig, chatConfig }
