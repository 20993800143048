import React from "react";
import styled from "styled-components";

type Props = {
  className?: any;
  height?: any;
  width?: any;
};

const Skeleton = ({ className, height, width }: Props) => {
  return (
    <section className={className} style={{ height: height, width: width }} />
  );
};

export default styled(Skeleton)`
  margin-top: 15px;
  border-radius: 8px;
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% -0%;
    }
  }
  &:white {
    background-image: linear-gradient(-90deg, #fff 0%, #e7edf1 50%, #fff 100%);
  }
`;
